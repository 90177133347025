import React, {Component} from "react"
import styles from "./SendButton.module.css"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import {observer} from "mobx-react"
import {computed, observable} from "mobx"
import {AxiosInstance} from "axios"
import {Role} from "lib/gate/interfaces"

const axiosModule = require("axios")

interface Props {
    role: Role
    email: string
    doc_ids: number[]
    urls: {subject_url; html_url; text_url}
    model: Model
    gate: Gate
    onClose: () => void
    isLoad: (val) => void
}

@observer
export class SendButton extends Component<Props, {}> {
    private axios: AxiosInstance = axiosModule.create()

    @observable
    private mode: "up" | "loading" | "successful" | "error" | "errorStaff" = "up"

    @computed
    private get disabled(): boolean {
        if (this.props.doc_ids.length === 0) {
            return true
        }
        if (!this.props.email) {
            return true
        }
        if (this.props.email === "") {
            return true
        }

        return false
    }

    public render() {
        return (
            <div className={styles.root}>
                {this.mode === "up" && (
                    <button disabled={this.disabled} onClick={this.onClick.bind(this)}>
                        Send
                    </button>
                )}
                {["error", "errorStaff"].indexOf(this.mode) >= 0 && (
                    <button disabled={this.disabled} onClick={this.onClick.bind(this)}>
                        Re-Send
                    </button>
                )}
                {this.mode === "loading" && <p>Sending ...</p>}
                {this.mode === "error" && <p>Error sending email</p>}
                {this.mode === "successful" && <p>Email has been sent</p>}
                {this.mode === "errorStaff" && <p>Please enter valid staff email</p>}
            </div>
        )
    }

    private onClick() {
        this.onSendEmail()
    }

    private async onSendEmail() {
        let data
        if (this.props.role === "staff") {
            const staffUsers = this.props.model.staffUsers
            let staffId
            for (let i = 0; i < staffUsers.length; i++) {
                if (this.props.email == staffUsers[i].email) {
                    staffId = staffUsers[i].id
                    break
                }
            }
            if (staffId == undefined) {
                this.mode = "errorStaff"
                return
            }
            this.props.isLoad(true)
            data = {
                doc_ids: this.props.doc_ids,
                email: await this.getEmailObject(),
                staff_id: staffId
            }
        } else {
            this.props.isLoad(true)
            data = {
                doc_ids: this.props.doc_ids,
                email_object: await this.getEmailObject(),
                email: this.props.email
            }
        }

        console.log(data)

        this.mode = "loading"
        let r

        if (this.props.role === "student") {
            r = await this.props.gate.request(`/emails/send-to-student`, {data})
        }

        if (this.props.role === "parent") {
            r = await this.props.gate.request(`/emails/send-to-parent`, {data})
        }

        if (this.props.role === "staff") {
            r = await this.props.gate.request(`/emails/send-to-staff/all-doc`, {data})
        }

        this.props.isLoad(false)
        this.props.onClose()
        if (r.success) {
            this.mode = "successful"
            this.props.model.alert("Email has been sent")
            this.props.model.loadPacks()
        } else {
            this.mode = "error"
            this.props.model.alert("Error! Can not send email")
        }
    }

    private async getEmailObject() {
        const {subject_url, html_url, text_url} = this.props.urls
        console.log(subject_url, html_url, text_url)
        let emailData = await Promise.all([
            this.axios.get(subject_url),
            this.axios.get(html_url),
            this.axios.get(text_url)
        ])
        const subject = emailData[0].data
        const html = emailData[1].data
        const text = emailData[2].data

        return {subject, html, text}
    }
}
