import React, {Component} from "react"
import {observer} from "mobx-react"
import {contentEditable} from "components/utils/contentEditable"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"

import styles from "./Package.module.css"
import {api_response} from "lib/gate/interfaces"
import {observable} from "mobx"
import {NewDocumentPopup} from "./parts/NewDocumentPopup"
import {SendEmailPopup} from "./parts/SendEmailPopup"
import {Table} from "./parts/table"

const EditableDiv = contentEditable("div")

interface Props {
    pack: api_response.Pack
    model: Model
    gate: Gate
}

@observer
export class Package extends Component<Props, {}> {
    @observable
    private newDocumentPopup = false

    @observable
    private sendEmailPopup = false

    private async onPackNameChange(value) {
        const data = {
            name: value
        }
        await this.props.gate.request(`/packs/${this.props.pack.id}/edit`, {data})
        this.props.model.loadPacks()
    }

    public render() {
        const {model, gate, pack} = this.props
        const user = model.user

        if (!user) {
            return "Loading ..."
        }

        const visitor = model.visitor

        const isStaff: boolean = visitor.role === "staff" || visitor.role === "admin"

        return (
            <div className={styles.box}>
                {this.sendEmailPopup && (
                    <SendEmailPopup
                        pack={this.props.pack}
                        model={model}
                        gate={gate}
                        onClose={() => {
                            this.sendEmailPopup = false
                        }}
                    />
                )}
                {this.newDocumentPopup && (
                    <NewDocumentPopup
                        pack={this.props.pack}
                        model={model}
                        gate={gate}
                        onClose={() => {
                            this.newDocumentPopup = false
                        }}
                    />
                )}
                <EditableDiv
                    value={this.props.pack.name}
                    editOnClick={isStaff}
                    onSave={this.onPackNameChange.bind(this)}
                    className={styles.title}
                />

                <Table pack={pack} gate={gate} isLoading={false} model={model} />
            </div>
        )
    }
}
