import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./popup.module.css"
import classNames from "classnames"
import {ReactComponent as Icon} from "./img/alert.svg"

interface Props {
    title?: string
    message: string
    onOk: () => void
    onCancel?: () => void
}

export class Popup extends Component<Props, {}> {
    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    public render() {
        const {onCancel, onOk, message, title} = this.props

        let html = (
            <article className={styles.root}>
                <div className={styles.iconWrapper}>
                    <Icon width="39" height="39" className={styles.icon} />
                </div>
                <div className={styles.body}>
                    <div className={styles.content}>
                        {title && <h1 className={styles.title}>{title}</h1>}
                        <p className={styles.text}>{message}</p>
                    </div>
                    <div className={styles.footer}>
                        {onCancel && (
                            <button className={styles.button} onClick={onCancel}>
                                Cancel
                            </button>
                        )}
                        <button className={classNames(styles.button, styles.ok)} onClick={onOk}>
                            OK
                        </button>
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}
