import React from "react"

interface Props {
    onSave
    value
    editOnClick
    className?: string
}

// Based on this article
// https://medium.com/@vraa/inline-edit-using-higher-order-components-in-react-7828687c120c

export function contentEditable(WrappedComponent) {
    return class extends React.Component<Props, any> {
        private domElm: HTMLElement

        state = {
            editing: false
        }

        toggleEdit = (e) => {
            e.stopPropagation()
            if (this.state.editing) {
                this.cancel()
            } else {
                this.edit()
            }
        }

        edit = () => {
            this.setState(
                {
                    editing: true
                },
                () => {
                    this.domElm.focus()
                }
            )
        }

        save = () => {
            this.setState(
                {
                    editing: false
                },
                () => {
                    if (this.props.onSave && this.isValueChanged()) {
                        // console.log("Value is changed", this.domElm.textContent)
                        this.props.onSave(this.domElm.textContent)
                    }
                }
            )
        }

        cancel = () => {
            this.setState({
                editing: false
            })
        }

        isValueChanged = () => {
            return this.props.value !== this.domElm.textContent
        }

        handleKeyDown = (e) => {
            const {key} = e
            switch (key) {
                case "Enter":
                case "Escape":
                    this.save()
                    break
            }
        }

        render() {
            let editOnClick = true
            const {editing} = this.state
            if (this.props.editOnClick !== undefined) {
                editOnClick = this.props.editOnClick
            }

            const newProps = {...this.props}
            delete newProps.editOnClick
            delete newProps.onSave

            return (
                <WrappedComponent
                    className={this.props.className + (editing ? "editing" : "")}
                    onClick={editOnClick ? this.toggleEdit : undefined}
                    contentEditable={editing}
                    suppressContentEditableWarning={true}
                    ref={(domNode) => {
                        this.domElm = domNode
                    }}
                    onBlur={this.save}
                    onKeyDown={this.handleKeyDown}
                    {...newProps}>
                    {this.props.value}
                </WrappedComponent>
            )
        }
    }
}
