import React from "react"
import ReactDOM from "react-dom"
import {App} from "./App"
import {Gate} from "./lib/gate/Gate"
import {Model} from "./Model"
import {GateListener} from "./lib/gate/GateListener"
import {IndexPage} from "./Pages/index-page"
import {getParameterByName} from "lib/functions/getParameterByName"
import {createBrowserHistory} from "history"
import {Router as ReactRouter} from "react-router"
import "./index.css"

const token = getParameterByName("token")

const gate = new Gate()
new GateListener(gate)
const history = createBrowserHistory()
const model = new Model(gate, history)

main()

async function main() {
    if (!token) {
        // alert("GET parameter 'token' is required!")

        showIndex()
        return
    }
    gate.setToken(token)

    model.loadStaffUsers()
    await Promise.all([model.loadMe(), model.loadAppSettings()])

    ReactDOM.render(
        <ReactRouter history={history}>
            <App model={model} gate={gate} />
        </ReactRouter>,
        document.getElementById("root")
    )
}

function showIndex() {
    ReactDOM.render(<IndexPage gate={gate} />, document.getElementById("root"))
}
