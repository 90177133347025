import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./StatusCell.module.css"
import {observer} from "mobx-react"
import {Icons} from "./parts/Icons"

type Item = api_response.Agreement | api_response.Doc | api_response.Plan

interface Props {
    item: Item
}

@observer
export class StatusCell extends Component<Props, {}> {
    public render() {
        const {item} = this.props
        return (
            <td className={styles.cell}>
                <div className={styles.wrapper}>
                    <Icons name={item.status} />
                    <span> {item.status}</span>
                </div>
            </td>
        )
    }
}
