import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./UpdatedCell.module.css"
import {observer} from "mobx-react"
import {computed} from "mobx"
import moment from "moment"

type Item = api_response.Agreement | api_response.Doc | api_response.Plan

interface Props {
    item: Item
}

@observer
export class UpdatedCell extends Component<Props, {}> {
    @computed
    private get data(): string {
        const {item} = this.props
        return moment(item.updated_at).format("Do MMM YYYY")
    }

    public render() {
        return <td className={styles.cell}>{this.data}</td>
    }
}
