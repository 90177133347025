import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./ChangeStatusPopup.module.css"
import classNames from "classnames"
import {DocumentStatus} from "lib/gate/interfaces"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {Gate} from "lib/gate/Gate"
import {ReactComponent as Icon} from "./img/alert.svg"
import {TextArea} from "components/TextArea"

interface Props {
    gate: Gate
    document_id: number
    old_status: DocumentStatus
    new_status: DocumentStatus
    onClose: () => void
}

@observer
export class ChangeStatusPopup extends Component<Props, {}> {
    @observable
    private comment: string = ""

    @observable
    private isLoading = false

    private capitalize(s: string) {
        return s[0].toUpperCase() + s.slice(1)
    }

    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
        this.comment = ""
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    public render() {
        const {old_status, new_status, onClose} = this.props

        let html = (
            <article className={styles.root}>
                <div className={styles.wrapper}>
                    <Icon width="39" height="39" className={styles.icon} />
                </div>
                <div className={styles.body}>
                    <h1 className={styles.title}>Change status</h1>
                    <p className={styles.text}>
                        <span>
                            From: <b> {this.capitalize(old_status)} </b>
                        </span>
                        <span>
                            To: <b> {this.capitalize(new_status)} </b>
                        </span>
                    </p>

                    <TextArea
                        rows={2}
                        placeholder="Add an optional comment"
                        value={this.comment}
                        onChange={(event) => {
                            this.comment = event.target.value
                        }}
                    />

                    <div className={styles.footer}>
                        <button className={styles.button} onClick={onClose}>
                            Cancel
                        </button>

                        <button
                            disabled={this.isLoading}
                            className={classNames(styles.button, styles.ok)}
                            onClick={async () => {
                                this.isLoading = true
                                const {gate, document_id, old_status, new_status} = this.props
                                await gate.request(`/docs/${document_id}/change-status`, {
                                    data: {
                                        comment: this.comment,
                                        old_status,
                                        new_status
                                    }
                                })
                                this.isLoading = false
                                this.props.onClose()
                            }}>
                            OK
                        </button>
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}
