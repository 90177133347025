import React, {Component} from "react"
import styles from "./TextArea.module.css"

interface Props {
    placeholder?: string
    onChange: (e) => void
    disabled?: boolean
    value: string
    rows?: number
}

export class TextArea extends Component<Props, {}> {
    public render() {
        const {placeholder, disabled, value, rows = 5, onChange} = this.props
        return (
            <textarea
                value={value}
                rows={rows}
                className={styles.input}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
            />
        )
    }
}
