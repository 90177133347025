import React, {Component} from "react"
import styles from "./Icons.module.css"
import {ReactComponent as CheckIcon} from "./img/check.svg"
import {ReactComponent as EmptyIcon} from "./img/circle-empty.svg"
import {ReactComponent as WarningIcon} from "./img/warning-triangle.svg"
import {ReactComponent as PaperPlaneIcon} from "./img/paper-plane.svg"
import {ReactComponent as EyeIcon} from "./img/eye.svg"
import {DocumentStatus} from "lib/gate/interfaces"

interface Props {
    name: DocumentStatus
}

export class Icons extends Component<Props, {}> {
    public render() {
        const {name} = this.props

        return <React.Fragment>{getIcons(name)}</React.Fragment>
    }
}

function getIcons(name: DocumentStatus) {
    const props = {className: styles.icon, width: "24", height: "24"}
    switch (name) {
        case "not ready":
            return <WarningIcon {...props} />
        case "sent to student":
            return <PaperPlaneIcon {...props} />
        case "viewed by student":
            return <EyeIcon {...props} />
        case "completed":
            return <CheckIcon {...props} />

        default:
            return <EmptyIcon {...props} />
    }
}
