import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./NewDocumentPopup.module.css"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import {StaffDropdownSelect} from "components/StaffDropdownSelect"
import {AgreementTypeDropdownSelect} from "components/AgreementTypeDropdownSelect"
import {api_response} from "lib/gate/interfaces"
import {ReactComponent as Icon} from "./parts/img/new-document.svg"
import {CloseButton} from "components/popupButtons/CloseButton"
import {CheckBox} from "./parts/check-box"
import {PopupButton} from "components/popupButtons/PopupButton"

interface Props {
    pack: api_response.Pack
    model: Model
    gate: Gate
    onClose: () => void
}

@observer
export class NewDocumentPopup extends Component<Props, {}> {
    @observable
    private type: api_response.DocumentType = "plan"

    @observable
    private agreementType: string

    @observable
    private agreementTypeReset: string = null

    @observable
    private staffId: number = null

    @observable
    private staffIdReset: number = null

    @observable
    private previousStaff: any

    @observable
    private previousAgreement: any

    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    @computed
    private get isOkDisabled(): boolean {
        if (this.type === "plan" && this.staffId) {
            return false
        }

        if (this.type === "agreement" && this.staffId && this.agreementType) {
            return false
        }

        if (this.type === "set1" && this.staffId) {
            return false
        }

        if (this.type === "agreement2" && this.staffId && this.agreementType) {
            return false
        }

        return true
    }

    private async addAgreement(pack_id, staffId: number, agreement_type: string, data_type: string) {
        const data = {
            pack_id: pack_id,
            type: data_type,
            domain: process.env.REACT_APP_AGREEMENT_DOMAIN,
            json_data: {},
            json_settings: {
                staff_ids: [staffId],
                agreement_type: agreement_type
            }
        }
        await this.props.gate.request("/docs/add", {data})
        await this.props.model.loadPacks()
        this.props.onClose()
    }

    private async addPlan(pack_id, staffId: number) {
        console.log("testing here add plan")
        const data = {
            pack_id: pack_id,
            type: "plan",
            domain: process.env.REACT_APP_PLAN_DOMAIN,
            json_data: {},
            json_settings: {
                staff_ids: [staffId],
                settings_id: 1
            }
        }
        await this.props.gate.request("/docs/add", {data})
        await this.props.model.loadPacks()
        this.props.onClose()
    }

    private async addSet1(pack_id, staffId: number) {
        const data = {
            pack_id: pack_id,
            type: "set1",
            domain: process.env.REACT_APP_SET1_DOMAIN,
            json_data: {},
            json_settings: {
                staff_ids: [staffId],
                agreement_type: "set1-02-2023"
            }
        }
        await this.props.gate.request("/docs/add", {data})
        await this.props.model.loadPacks()
        this.props.onClose()
    }

    public changeStaff(type, staffId: number, e) {
        if (type == this.type) {
            this.staffId = staffId
        } else {
            alert("Please select staff respective to selected document type")
            return
        }

        e.selectId = staffId
        this.previousStaff = e
    }

    public resetStaff() {
        this.staffId = null
        if (this.previousStaff != undefined) {
            this.previousStaff.selectId = null
        }
        
        this.agreementType = null
        if (this.previousAgreement != undefined) {
            this.previousAgreement.selectId = null
        }
    }

    public changeAgreement(type, agreementType: string, e) {
        if ((type == "agreement" && this.type == "agreement" && this.props.model.agreementTypes.indexOf(agreementType) >= 0) || (type == "agreement2" && this.type == "agreement2" && this.props.model.agreement2Types.indexOf(agreementType) >= 0)) {
            this.agreementType = agreementType
        } else {
            alert("Please select sub-document respective to selected document type")
            return
        }

        e.selectId = agreementType
        this.previousAgreement = e
    }

    public render() {
        const {onClose, model} = this.props

        let html = (
            <article className={styles.popup}>
                <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} width="39" height="39" />
                </div>
                <div className={styles.body}>
                    <div className={styles.closeButtonWrapper}>
                        <CloseButton onClick={this.props.onClose} />
                    </div>
                    <h1 className={styles.title}>Add Documents</h1>

                    <div className={styles.checkBoxWrapper}>
                        <div className={styles.inner}>
                            <CheckBox
                                text="Enrollment Agreement"
                                name="type"
                                checked={this.type === "agreement"}
                                onChange={() => {
                                    this.type = "agreement"
                                    this.resetStaff()
                                }}
                            />
                            <StaffDropdownSelect
                                disabled={false}
                                staffUsers={model.staffUsers}
                                isLoading={model.staffUsersIsLoading}
                                selectedId={this.staffIdReset}
                                onChange={(e, value: number) => {
                                    this.changeStaff("agreement", value, e)
                                }}
                                placeholder="School official"
                            />
                            <div></div>
                            <AgreementTypeDropdownSelect
                                disabled={false}
                                agreementTypes={model.agreementTypes}
                                selectedType={this.agreementTypeReset}
                                onChange={(e, type: string) => {
                                    // @ts-ignore
                                    this.changeAgreement("agreement", type, e)
                                }}
                                placeholder="Enrollment Agreement"
                            />
                        </div>
                        <div className={styles.inner}>
                            <CheckBox
                                text="Other FA Documents"
                                name="type"
                                checked={this.type === "set1"}
                                onChange={() => {
                                    this.type = "set1"
                                    this.resetStaff()
                                }}
                            />
                            <StaffDropdownSelect
                                disabled={false}
                                staffUsers={model.staffUsers}
                                isLoading={model.staffUsersIsLoading}
                                selectedId={this.staffIdReset}
                                onChange={(e, value: number) => {
                                    this.changeStaff("set1", value, e)
                                }}
                                placeholder="School official"
                            />
                        </div>
                        <div className={styles.inner}>
                            <CheckBox
                                text="Other Documents"
                                name="type"
                                checked={this.type === "agreement2"}
                                onChange={() => {
                                    this.type = "agreement2"
                                    this.resetStaff()
                                }}
                            />
                            <StaffDropdownSelect
                                disabled={false}
                                staffUsers={model.staffUsers}
                                isLoading={model.staffUsersIsLoading}
                                selectedId={this.staffIdReset}
                                onChange={(e, value: number) => {
                                    this.changeStaff("agreement2", value, e)
                                }}
                                placeholder="School official"
                            />
                            <div></div>
                            <AgreementTypeDropdownSelect
                                disabled={false}
                                agreementTypes={model.agreement2Types}
                                selectedType={this.agreementTypeReset}
                                onChange={(e, type: string) => {
                                    // @ts-ignore
                                    this.changeAgreement("agreement2", type, e)
                                }}
                                placeholder="Other Documents"
                            />
                        </div>
                    </div>

                    <br />

                    <div className={styles.buttonWrapper}>
                        {onClose && <PopupButton text=" Cancel" onClick={onClose} />}
                        <PopupButton
                            text="OK"
                            type="secondary"
                            onClick={() => {
                                const pack_id = this.props.pack.id
                                const staff_id = this.staffId
                                if (this.type === "agreement") {
                                    this.addAgreement(pack_id, staff_id, this.agreementType, this.type)
                                }

                                if (this.type === "plan") {
                                    this.addPlan(pack_id, staff_id)
                                }

                                if (this.type === "set1") {
                                    this.addSet1(pack_id, staff_id)
                                }

                                if (this.type === "agreement2") {
                                    this.addAgreement(pack_id, staff_id, this.agreementType, this.type)
                                }
                            }}
                            disabled={this.isOkDisabled}
                        />
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}
