import React, {Component} from "react"
import styles from "./Button.module.css"
import classNames from "classnames"

interface Props {
    label: string
    disabled?: boolean
    type?: "secondary"
    onClick: () => void
}

export class Button extends Component<Props, {}> {
    private onClick(event) {
        const {onClick} = this.props
        event.preventDefault()
        event.stopPropagation()
        onClick()
    }

    public render() {
        const {label, disabled, type} = this.props
        return (
            <button
                className={classNames(styles.button, type === "secondary" && styles["button--secondary"])}
                onClick={this.onClick.bind(this)}
                disabled={disabled}>
                {label}
            </button>
        )
    }
}
