import React from "react"
import styles from "./check-box.module.css"
import {ReactComponent as CheckerIcon} from "./icons/checked.svg"
import {ReactComponent as HoverIcon} from "./icons/hover.svg"

interface Props {
    text: string
    onChange: (event) => void
    name: string
    disabled?: boolean
    checked: boolean
}

export function CheckBox(props: Props) {
    const {checked, onChange, disabled, text} = props
    return (
        <label className={styles.root}>
            <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} className={styles.input} />
            <HoverIcon className={`${styles.icon} ${styles.hover}`} />
            <CheckerIcon className={`${styles.icon} ${styles.checked}`} />
            <span className={styles.text}>{text}</span>
        </label>
    )
}
