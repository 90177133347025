import {action, computed, observable} from "mobx"
import {Gate} from "./lib/gate/Gate"
import axios from "axios"
import {Action, History, Location} from "history"
import {api_response, User, Visitor} from "./lib/gate/interfaces"

export class Model {
    @observable
    public location: Location

    @observable
    public path: string

    @observable
    public visitor: Visitor

    @observable
    public user: User | undefined

    @observable
    public packsList: api_response.Pack[] = []

    @observable
    public docsList: api_response.Doc[] = []

    @observable
    public agreementTypes: string[] = []

    @observable
    public agreement2Types: string[] = []

    @observable
    public staffUsers: User[] = []

    @observable
    public staffUsersIsLoading = false

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    constructor(private gate: Gate, private history: History) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH")
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async loadTypes() {
        let typesResponse = await axios.get(`${process.env.REACT_APP_AGREEMENT_DOMAIN}/api/get-types`)
        this.agreementTypes = typesResponse.data.data
    }

    public async loadTypes2() {
        let types2Response = await axios.get(`${process.env.REACT_APP_AGREEMENT_DOMAIN}/api/get-types-2`)
        this.agreement2Types = types2Response.data.data
    }

    public async loadPacks() {
        const data = {
            sort: {
                orderBy: "created_at",
                order: "ASC"
            },
            filter: {
                text: "",
                user_ids: [this.user.id]
            },
            pagination: {
                page: 1,
                pageSize: 10
            }
        }
        let r = await this.gate.request("/packs/get-all", {data})
        this.packsList = r.data
    }

    public async loadDocs() {
        const data = {
            sort: {
                orderBy: "created_at",
                order: "ASC"
            },
            filter: {
                text: "",
                user_ids: [this.user.id]
            },
            pagination: {
                page: 1,
                pageSize: 10
            }
        }
        let r = await this.gate.request("/docs/get-all", {data})
        this.docsList = r.data
    }

    public async loadStaffUsers() {
        this.staffUsersIsLoading = true
        let r = await this.gate.request("/users/get-all-staff")
        this.staffUsersIsLoading = false
        this.staffUsers = r.data
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async loadUser(user_id: number): Promise<void> {
        let r = await this.gate.request(`/users/${user_id}/get`)
        this.user = r.data
    }

    public getHistory(): History {
        return this.history
    }
}
