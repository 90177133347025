import React, {Component} from "react"
import {User} from "lib/gate/interfaces"
import {observer} from "mobx-react"
import {computed, observable} from "mobx"
import {DropdownOption} from "../Dropdown"
import {KlassDropdown} from "../KlassDropdown"

interface Props {
    disabled: boolean
    isLoading: boolean
    staffUsers: User[]
    selectedId: number | null
    onChange: (e, selectedId: number | null) => void
    placeholder?: string
}

@observer
export class StaffDropdownSelect extends Component<Props, {}> {

    @observable
    public selectId: number = this.props.selectedId

    @computed
    private get options(): DropdownOption[] {
        const data = this.props.staffUsers
        const selectedId = this.selectId

        let firstOption: DropdownOption = {
            label: "Unassigned",
            value: 0,
            icon: "WARNING",
            border: true
        }
        if (selectedId) {
            const user = data.find((user) => +user.id === +selectedId)
            if (user) {
                firstOption = {
                    label: `${user.first_name} ${user.last_name}`,
                    value: user.id,
                    icon: "SMALL_PROFILE",
                    border: true
                }
            }
        }
        const options = data
            .filter((user) => +user.id !== +selectedId)
            .map((user: User) => {
                const option: DropdownOption = {
                    label: `${user.first_name} ${user.last_name}`,
                    value: user.id,
                    icon: "SMALL_PROFILE"
                }
                return option
            })
        return [firstOption, ...options]
    }

    public render() {
        return (
            <KlassDropdown
                isDisabled={this.props.disabled}
                isMulti={false}
                isLoading={this.props.isLoading}
                options={this.options}
                value={this.options.filter((el) => {
                    return el.value === this.selectId
                })}
                onChange={(item) => {
                    console.log(item)
                    this.props.onChange(this, item.value)
                }}
                placeholder={this.props.placeholder}
            />
        )
    }
}
