import React, {Component} from "react"

interface Props {
    tokens: {
        parent_138045_token: string
        student_138045_token: string
        staff_60347_token: string
        admin_1_token: string
    }
}

export class DebugLinks extends Component<Props, {}> {
    public render() {
        const {parent_138045_token, student_138045_token, staff_60347_token, admin_1_token} = this.props.tokens

        return (
            <div style={{padding: "50px"}}>
                <h1>Test app links</h1>
                <a href={`/users/54115?token=${student_138045_token}`}>
                    1. Student (138045) try open other student's (54115) page
                </a>
                <br />
                <a href={`/users/138045?token=${student_138045_token}`}>2. Student (138045) open his own page</a>
                <br />
                <a href={`/users/138045?token=${staff_60347_token}`}>
                    3. Staff user (60347) open student's (138045) page
                </a>
                <br />
                <a href={`/users/138045?token=${admin_1_token}`}>4. Admin user (1) open student's (138045) page</a>
                <br />

                <a href={`/users/138045?token=${parent_138045_token}`}>
                    5. Parent of student Test Test (student_id: 138045) open student's (138045) page
                </a>
            </div>
        )
    }
}
