import React, {Component} from "react"
import {DebugLinks} from "./parts/DebugLinks"
import {observable} from "mobx"
import {observer} from "mobx-react"
import {Gate} from "../../lib/gate/Gate"

interface Props {
    gate: Gate
}

@observer
export class IndexPage extends Component<Props, {}> {
    @observable
    private tokens: undefined | any

    @observable
    private password: string = ""

    private async loadTokens() {
        const data = {
            password: this.password
        }
        const r = await this.props.gate.request("/temp/check-developer-password", {data})
        if (r.success) {
            this.tokens = r.data
        } else {
            alert("Wrong Developer's Password")
        }
    }

    public componentDidMount() {
        if (process.env.REACT_APP_DEVELOPER_PASSWORD) {
            this.password = process.env.REACT_APP_DEVELOPER_PASSWORD
            this.loadTokens()
        }
    }

    private onPasswordChange(event) {
        console.log(event.target.value)
        this.password = event.target.value
    }

    public render() {
        return (
            <div>
                <h1>Error. required token parameter is missing</h1>
                {!this.tokens && (
                    <div>
                        <strong>Input developer's password to view debug links:</strong>
                        <input type="text" value={this.password} onChange={this.onPasswordChange.bind(this)} />
                        <button onClick={this.loadTokens.bind(this)}>Sign in as developer</button>
                    </div>
                )}
                {this.tokens && <DebugLinks tokens={this.tokens} />}
            </div>
        )
    }
}
