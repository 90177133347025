import React from "react"
import styles from "./PopupButton.module.css"
import classNames from "classnames"

type ButtonType = "primary" | "secondary"

interface Props {
    text: string
    onClick: (event) => void
    type?: ButtonType
    disabled?: boolean
}

export function PopupButton({text, onClick, type, disabled}: Props) {
    return (
        <button
            onClick={onClick}
            className={classNames(
                styles.button,
                type === "primary" && styles.primary,
                type === "secondary" && styles.secondary
            )}
            disabled={disabled}>
            {text}
        </button>
    )
}
