import React from "react"
import {Link} from "react-router-dom"
import styles from "./BackButton.module.css"
import {ReactComponent as ArrowIcon} from "./arrow.svg"

interface Props {
    url: string
}

export class BackButton extends React.Component<Props, {}> {
    public render() {
        return (
            <Link className={styles.arrowLink} to={this.props.url} title="GO BACK">
                <ArrowIcon width="40" height="40" className={styles.arrowLinkIcon} />
            </Link>
        )
    }
}
