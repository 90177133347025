import React from "react"
import styles from "./ContextButton.module.css"
import {ReactComponent as EllipsesIcon} from "./img/ellipses.svg"

type ButtonType = "primary" | "secondary"

interface Props {
    onClick: (event) => void
    disabled?: boolean
}

export function ContextButton({onClick}: Props) {
    return (
        <button onClick={onClick} className={styles.button}>
            <EllipsesIcon className={styles.icon} width="24" height="24" />
        </button>
    )
}
