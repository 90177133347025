import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./CollegeAdminCell.module.css"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {Model} from "Model"

type Item = api_response.Agreement | api_response.Doc | api_response.Plan

interface Props {
    item: Item
    model: Model
}

@observer
export class CollegeAdminCell extends Component<Props, {}> {
    @computed
    private get admin(): string {
        const {item, model} = this.props
        const {staffUsers} = model
        const selectedId = item.json_settings.staff_ids
        let content: string = ""
        const admin = staffUsers.find((user) => +user.id === +selectedId)

        if (admin) {
            content = `${admin.first_name} ${admin.last_name}`
        }

        return content
    }

    public render() {
        return <td className={styles.cell}>{this.admin}</td>
    }
}
