import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./line.module.css"
import {observer} from "mobx-react"
import {Column} from "../../table"
import {Gate} from "lib/gate/Gate"
import {Model} from "Model"
import {CollegeAdminCell} from "./parts/CollegeAdminCell"
import {UpdatedCell} from "./parts/UpdatedCell"
import {StatusCell} from "./parts/StatusCell"
import {IconCell} from "./parts/IconCell"
import {ContextButton} from "../ContextButton"
import {DocumentTypeCell} from "./parts/DocumentTypeCell"
import {DescriptionCell} from "./parts/DescriptionCell"
import {SubMenu} from "../SubMenu"
import {observable} from "mobx"

interface Props {
    gate: Gate
    model: Model
    document: api_response.GenericDocument
    tableStructure: Column[]
}

@observer
export class Line extends Component<Props, {}> {
    @observable
    private showContextMenu = false

    public render() {
        const {tableStructure, gate, model, document} = this.props

        return (
            <tr>
                {tableStructure.map((column: Column) => {
                    return this.getCell(column)
                })}
                <td className={styles.cell}>
                    <div className={styles.wrapper}>
                        <ContextButton
                            onClick={(e) => {
                                e.stopPropagation()
                                this.showContextMenu = !this.showContextMenu
                            }}
                        />
                        {this.showContextMenu && (
                            <SubMenu
                                onClose={() => {
                                    this.showContextMenu = false
                                }}
                                items={[
                                    {
                                        id: "duplicate",
                                        title: "duplicate",
                                        onClick: async () => {
                                            await gate.request(`/docs/${document.id}/duplicate`)
                                            model.loadPacks()
                                        }
                                    },
                                    {
                                        id: "delete",
                                        title: "delete",
                                        onClick: async () => {
                                            await gate.request(`/docs/${document.id}/delete`)
                                            model.loadPacks()
                                        }
                                    }
                                ]}
                            />
                        )}
                    </div>
                </td>
            </tr>
        )
    }

    private getCell(c: Column): JSX.Element {
        const {document, gate, model} = this.props
        const token = gate.getToken()
        switch (c) {
            case "Document type":
                return <DocumentTypeCell key={`${document}document_type`} item={document} token={token} />

            case "Description":
                return <DescriptionCell key={`${document}description`} item={document} />

            case "College Admin":
                return <CollegeAdminCell key={`${document}college_admin`} item={document} model={model} />

            case "Updated":
                return <UpdatedCell key={`${document}updated`} item={document} />

            case "Status":
                return <StatusCell key={`${document}status`} item={document} />

            case "PDF":
                return <IconCell type="pdf" key={`${document}pdf`} item={document} token={token} />

            case "Signature":
                return <IconCell type="signature" key={`${document}signature`} item={document} token={token} />

            default:
                return null
        }
    }
}
