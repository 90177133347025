import React from "react"
import styles from "./Button.module.css"

type ButtonType = "primary" | "secondary"

interface Props {
    text: string
    onClick: (event) => void
    type?: ButtonType
}

export function Button({text, onClick, type = "primary"}: Props) {
    let cssClass = ""
    if (type === "secondary") {
        cssClass = styles["addButton--secondary"]
    }

    return (
        <button onClick={onClick} className={`${styles.addButton} ${styles["singlePackage__button"]} ${cssClass}`}>
            <span className={styles["addButton__text"]}>{text}</span>
            <svg
                className={styles["addButton__icon"]}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path
                    fillRule="evenodd"
                    d="M19 11h-6V5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2z"
                />
            </svg>
        </button>
    )
}
