import React, {Component} from "react"
import {observer} from "mobx-react"
import {computed, observable} from "mobx"
import {DropdownOption} from "../Dropdown"
import {KlassDropdown} from "../KlassDropdown"

interface Props {
    disabled: boolean
    agreementTypes: string[]
    selectedType: string | null
    onChange: (e, type: string | null) => void
    styles?: object
    placeholder?: string
}

@observer
export class AgreementTypeDropdownSelect extends Component<Props, {}> {

    @observable
    public selectId: string = this.props.selectedType

    @computed
    private get options(): DropdownOption<string>[] {
        return this.props.agreementTypes.map((type: string) => {
            return {
                label: type,
                value: type
            }
        })
    }

    public render() {
        const {disabled, agreementTypes, onChange, selectedType, placeholder} = this.props

        return (
            <KlassDropdown
                isDisabled={disabled}
                isMulti={false}
                isLoading={false}
                options={this.options}
                value={this.options.filter((el) => {
                    return el.value === this.selectId
                })}
                onChange={(item) => {
                    const type = item.value
                    if (agreementTypes.indexOf(type) !== -1) {
                        onChange(this, type)
                        return
                    }

                    onChange(this, null)
                }}
                placeholder={placeholder}
            />
        )
    }
}
