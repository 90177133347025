import React from "react"
import styles from "./check-box.module.css"
import {ReactComponent as IconChecked} from "./img/checked.svg"
import {ReactComponent as IconNormal} from "./img/normal.svg"

interface Props {
    text: string
    onChange: (event) => void
    disabled?: boolean
    checked: boolean
}

export function CheckBox(props: Props) {
    const {checked, onChange, disabled, text} = props
    return (
        <label className={styles.label}>
            <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} className={styles.input} />
            {!checked && <IconNormal className={styles.icon} width="24" height="24" />}
            {checked && <IconChecked className={styles.icon} width="24" height="24" />}
            {text}
        </label>
    )
}
