import React from "react"
import styles from "./Input.module.css"
import {ReactComponent as Icon} from "./parts/img/close.svg"

interface Props {
    title?: string
    placeholder: string
    onClear: (event) => void
    onChange: (event) => void
    value: string
    disabled?: boolean
}

export function Input(props: Props) {
    const {placeholder, value, onChange, onClear, disabled, title} = props
    return (
        <div className={styles.wrapper}>
            {title && <p className={styles.title}>{title}</p>}
            <input
                className={styles.input}
                type="text"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
            />
            <button onClick={onClear} className={styles.button} disabled={disabled}>
                <Icon className={styles.icon} />
            </button>
        </div>
    )
}
