import React, {Component} from "react"
import styles from "./SubMenu.module.css"
import {ReactComponent as DelIcon} from "./img/delete.svg"
import {ReactComponent as DuplicateIcon} from "./img/duplicate.svg"
import {ReactComponent as SentIcon} from "./img/paper-plane.svg"

interface Props {
    items: Item[]
    onClose: () => void
}

export type ItemId = "send email" | "duplicate" | "delete"

interface Item {
    id: ItemId
    title: string
    onClick: (event) => void
}

export class SubMenu extends Component<Props, {}> {
    private component: Node

    private onMouseDown(e: MouseEvent) {
        if (!this.component) {
            return
        }

        if (this.component.contains(e.target as Node)) {
            return
        }

        this.props.onClose()
    }

    public async componentWillMount() {
        document.addEventListener("mousedown", this.onMouseDown.bind(this))
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown.bind(this))
    }
    public render() {
        const {items, onClose} = this.props

        return (
            <div
                className={styles.wrapper}
                ref={(node) => {
                    this.component = node
                }}>
                <ul className={styles.list}>
                    {items.map((item: Item) => {
                        return (
                            <li key={item.id}>
                                <button
                                    type="button"
                                    className={styles.button}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        onClose()
                                        item.onClick(e)
                                    }}>
                                    <Icon id={item.id} />
                                    <span className={styles.text}>{item.title}</span>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

function Icon({id}: {id: ItemId}) {
    const props = {width: "24", height: "24", className: styles.icon}
    switch (id) {
        case "delete":
            return <DelIcon {...props} />
        case "duplicate":
            return <DuplicateIcon {...props} />
        case "send email":
            return <SentIcon {...props} />

        default:
            return null
    }
}
