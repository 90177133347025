import React, {Component} from "react"
import {route} from "./lib/functions/route"
import {Model} from "./Model"
import {observer} from "mobx-react"
import {Gate} from "./lib/gate/Gate"
import {DocPage} from "./Pages/doc-page"
import {FinAid} from "./Pages/finaid"
import {Visitor} from "./lib/gate/interfaces"
import {Popup} from "./components/popup"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class App extends Component<Props, {}> {
    public render() {
        const {model, gate} = this.props
        const path = model.path
        console.log(model.visitor)

        let html = <h1>404. Replace this HTML based on URL</h1>

        if (route("/users/:userid(/)*", path)) {
            let {userid} = route("/users/:userid(/)*", path)
            userid = parseInt(userid)
            if (this.allowViewUserPage(userid, model.visitor)) {
                html = <FinAid model={model} gate={gate} user_id={userid} />
            } else {
                html = <h1>You are not allowed to open this page</h1>
            }
        }

        if (route("/docs/:docid(/)", path)) {
            const {docid} = route("/docs/:docid(/)*", path)
            html = <DocPage model={model} gate={this.props.gate} document_id={docid} />
        }

        return (
            <>
                {model.popup.visible && (
                    <Popup
                        message={model.popup.message}
                        onOk={() => {
                            model.popup.visible = false
                            model.popup.message = ""
                        }}
                    />
                )}
                {html}
            </>
        )
    }

    private allowViewUserPage(user_id, visitor: Visitor): boolean {
        if (visitor.role === "admin" || visitor.role === "staff") {
            return true
        }

        if (user_id === visitor.id) {
            // Student can view his own page
            return true
        }

        if (user_id === visitor.ref_user_id) {
            // Parent can view his child page
            return true
        }

        return false
    }
}
